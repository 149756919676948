import React, {Component} from 'react';

class Error extends Component {
  render() {
    return (<div><h1>ERROR 404</h1></div>);
  }
}


export default Error;
